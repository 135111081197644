import React, { Component } from "react";
import "./App.css";
import axios from "axios";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";

class Departmants extends Component {
  constructor(props) {
    super(props);
    const cookies = new Cookies();
    let language = "";
    if ("undefined" === typeof cookies.get("language")) {
      language = "ua";
    } else {
      language = cookies.get("language");
    }
    this.state = {
      data: [{ department_id: "", name_ua: "", name_en: "", group_id: "" }],
      language: language
    };
  }

  componentDidMount() {
    console.log(this.state.department_id);
    axios("https://listsever.e-decanat-ifnmu.site/api.php", {
      method: "post",
      data: {
        task: "GetDepartmentGroup",
        department_id: 1
      },
      withCredentials: true
    }).then(response => this.setState({ data: response.data.data }));
  }

  render() {
    console.log(this.state);
    return (
      <div>
        <ul>
          {this.state.data.map(group => {
            return (
              <li>
                <Link to={"../GroupList/" + group.group_id}>
                  {group.name_en}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default Departmants;
