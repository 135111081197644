import React, { Component } from "react";
import "./App.css";
import axios from "axios";
import { Link } from "react-router-dom";

class Departmants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [{ department_id: "", name_ua: "", name_en: "", d_order: "" }]
    };
  }

  componentDidMount() {
    axios("https://listsever.e-decanat-ifnmu.site/api.php", {
      method: "post",
      data: { task: "GetDepartments" },
      withCredentials: true
    }).then(response => this.setState({ data: response.data.data }));
  }

  render() {
    console.log(this.state);
    return (
      <div>
        <ul>
          {this.state.data.map(department => {
            return (
              <li>
                <Link to={"Department/" + department.department_id}>
                  {department.name_ua}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default Departmants;
