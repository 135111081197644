import React, { Component } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import axios from "axios";

class Head extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: false,
      user: ""
    };
  }

  componentDidMount() {
    axios("https://listsever.e-decanat-ifnmu.site/api.php", {
      method: "post",
      data: {
        task: "GetLogInStatus"
      },
      withCredentials: true
    }).then(response =>
      this.setState({ login: response.data.login, name: response.data.name })
    );
  }

  render() {
    console.log("login");
    let leftbutton = "";
    if (this.state.login === true) {
      leftbutton = (
        <Nav>
          <Nav.Link href="#">Ваші записи({this.state.name})</Nav.Link>
          <Nav.Link href="https://listsever.e-decanat-ifnmu.site/logout.php">
            Вихід
          </Nav.Link>
        </Nav>
      );
    } else {
      leftbutton = (
        <Nav>
          <Nav.Link href="https://ifnmu.sharepoint.com/siteDeanMedical/SitePages/%D0%94%D0%BE%D0%BC%D0%B0%D1%88%D0%BD%D1%8F%D1%8F.aspx#">
            Увійти
          </Nav.Link>
        </Nav>
      );
    }

    return (
      <Container>
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
          <Navbar.Brand href="#home">E-decanat-IFNMU</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="../">Головна сторінка</Nav.Link>
              <Nav.Link href="../Department/1">
                Деканат медичного факультету
              </Nav.Link>
            </Nav>

            {leftbutton}
          </Navbar.Collapse>
        </Navbar>
      </Container>
    );
  }
}

export default Head;
