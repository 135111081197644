import React, { Component } from "react";
import "./App.css";
import { BrowserRouter, Route } from "react-router-dom";
import PrintGroupList from "./PrintGroupList";
import Departments from "./Departments";
import Department from "./Department";
import Head from "./Head";
import BootsrapEsential from "./BootsrapEssential";

const NewRoute = () => {
  return (
    <div>
      <p>Hello World</p>
    </div>
  );
};

class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    console.log(this.state);
    return (
      <div>
        <Head />
        <BootsrapEsential />
        <BrowserRouter>
          <Route path="/" component={Department} exact />
          <Route path={"/Department/:id"} component={Department} exact />
          <Route path="/GroupList/:id" component={PrintGroupList} />
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
