import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const ModalConfirm = props => {
  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.modalData.modalText.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.modalData.modalText.descript}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          {props.modalData.modalText.cancle}
        </Button>
        <Button
          variant="primary"
          onClick={() =>
            props.confirmedModalConformation(
              props.modalData.task,
              props.modalData.taskData
            )
          }
        >
          {props.modalData.modalText.ok}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalConfirm;
