import React from "react";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import "./App.css";

const TableForList = props => {
  var global_language = "ua";
  var table = props.table;

  let textLink = {};
  var table_head = "";
  var str_table_header = {};
  if (global_language === "en") {
    str_table_header = {
      first_name: "First Name",
      last_name: "Last Name",
      course: "Course/Group",
      group: "Group",
      time: "Time"
    };
  } else if (global_language === "ua") {
    str_table_header = {
      first_name: "Ім'я",
      last_name: "Прізвище",
      course: "Курс/Група",
      group: "Група",
      time: "Час"
    };
  }
  if (table.time_between === 0) {
    table_head = [
      "#",

      str_table_header["last_name"],
      str_table_header["first_name"],
      str_table_header["course"]
    ];
  } else {
    table_head = [
      str_table_header["time"],
      str_table_header["last_name"],
      str_table_header["first_name"],
      str_table_header["course"]
    ];
  }
  var start_time = table.date * 1000;
  var curren_time = 0;
  var index = 0;

  let showEmpty = props.showFreeRecords;
  console.log(showEmpty);
  return (
    <Table hover>
      <thead>
        <tr>
          {table_head.map(cell => {
            return <td key={cell}>{cell}</td>;
          })}
        </tr>
      </thead>
      <tbody>
        {table.records.map(row => {
          let trStyle = {};
          curren_time = new Date(start_time);
          var hours = curren_time.getHours();
          var minutes = "0" + curren_time.getMinutes();
          var formattedTime = hours + ":" + minutes.substr(-2);
          start_time = start_time + table.time_between * 1000 * 60;
          index = index + 1;
          let indexForFunction = index;
          let fn = "";
          let ln = "Місце вільне";
          let admin_cell = "";
          let course = (
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={() =>
                props.buttonPress({
                  task: "CreateRecord",
                  taskData: {
                    number_in_list: indexForFunction,
                    list_id: table.list_id,
                    listIndex: table.ListIndex
                  }
                })
              }
            >
              Зареєструватись
            </Button>
          );
          if (showEmpty === true && row !== "empty") {
            if (row.semail === 0) {
              return "";
            }
          }

          if (row === "empty") {
            trStyle = { backgroundColor: "#F3FFEF" };
          }

          if (row === "deleted") {
            trStyle = { backgroundColor: "#E29693" };
            ln = "Видалено";
            course = (
              <Button
                variant="outline-secondary"
                size="sm"
                onClick={() => alert("функція в розробці")}
              >
                Скасувати видаленя
              </Button>
            );
          }
          if ((row !== "empty") & (row !== "deleted")) {
            fn = row.sfirstname;
            ln = row.slastname;
            course = row.scourse + "/" + row.sgroup;
          }
          if (row.semail === 1) {
            ln = ln + "(Ваш запис)";
            course = (
              <Button
                variant="outline-secondary"
                size="sm"
                onClick={() =>
                  props.buttonPress({
                    task: "CancleRecord",
                    modalText: {
                      title: "Скасування реєстрації",
                      descript: "Ви дійсно бажаєте скасувати реєстрацію?",
                      ok: "Так",
                      cancle: "Закрити"
                    },
                    taskData: {
                      number_in_list: indexForFunction,
                      list_id: table.list_id,
                      listIndex: table.ListIndex
                    }
                  })
                }
              >
                Скасувати запис
              </Button>
            );
            trStyle = { backgroundColor: "#D8EDF7" };
          }

          let timetd = "";
          if (table.time_between !== 0) {
            timetd = <td>{formattedTime}</td>;
          } else {
            timetd = <td>{index}</td>;
          }

          if (
            (table.admin === true) &
            (row !== "empty") &
            (row !== "deleted")
          ) {
            admin_cell = (
              <td>
                <Button
                  variant="link"
                  size="sm"
                  onClick={() => alert("функція в розробці")}
                >
                  Не був
                </Button>
                /
                <Button
                  size="sm"
                  variant="link"
                  onClick={() =>
                    props.buttonPress({
                      task: "CancleRecordAdmin",
                      modalText: {
                        title: "Скасування реєстрації",
                        descript: "Ви дійсно бажаєте скасувати реєстрацію?",
                        ok: "Так",
                        cancle: "Закрити"
                      },
                      taskData: {
                        number_in_list: indexForFunction,
                        list_id: table.list_id,
                        listIndex: table.ListIndex
                      }
                    })
                  }
                >
                  Видалити
                </Button>
/
                <Button
                  size="sm"
                  variant="link"
                  onClick={() =>
                    props.buttonPress({
                      task: "BanStudent",
                      modalText: {
                        title: "Блокування студента",
                        descript: "Ви дійсно бажаєте заблокувати даного студента?",
                        ok: "Так",
                        cancle: "Закрити"
                      },
                      taskData: {
                        number_in_list: indexForFunction,
                        list_id: table.list_id,
                        listIndex: table.ListIndex
                      }
                    })
                  }
                >
                  Заблокувати студента
                </Button>
              </td>
            );
          }
          if ((table.admin === true) & (row === "empty")) {
            admin_cell = <td />;
          }

          return (
            <tr style={trStyle} key={index}>
              {timetd}
              <td> {ln}</td>
              <td> {fn}</td>
              <td> {course}</td>
              {admin_cell}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default TableForList;
