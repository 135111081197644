import React from "react";
import Form from "react-bootstrap/Form";
import "./App.css";
import TimeForHeader from "./TimeForHeader";
import TableForList from "./TableForList";
import Restriction from "./Restriction";

const List = props => {
  let table = props.listdata;
  return (
    <div>
      <div className={"ListDepartmentName"}>{table.department}</div>
      <div className={"ListName"}>{table.name}</div>
      <TimeForHeader timestamp={table.date} />
      <Form>
        <div key={`default-checkbox`} className="mb-3">
          <Form.Check
            type="checkbox"
            id={`default-checkbox`}
            label={`Показувати тільки вільні місця`}
            onChange={() => props.showFreeRecords(table.ListIndex)}
          />
        </div>
      </Form>
      <Restriction restriction={table.restriction} />
      <TableForList
        table={table}
        buttonPress={props.buttonPress}
        showFreeRecords={table.showFreeRecords}
      />
    </div>
  );
};

export default List;
