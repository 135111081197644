import React, { Component } from "react";

import axios from "axios";
import "./App.css";
import List from "./List";
import ModalConfirm from "./ModalConfirm";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

class PrintGroupList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [
        {
          department: "",
          date: 1556200730000,
          records: ["empty", "empty"],
          list_id: 1,
          showFreeRecords: false,
          restriction: [{ courses: "", speciality: { ua: "", en: "" } }]
        },
        {
          department: "",
          date: 1556200730000,
          records: ["", ""],
          list_id: 2,
          showFreeRecords: false,
          restriction: [{ courses: "", speciality: { ua: "", en: "" } }]
        }
      ],
      modalShow: false,
      modalData: {
        modalText: {
          title: "s",
          descript: "s",
          ok: "",
          cancle: ""
        }
      },
      login: false
    };
  }

  buttonPress = props => {
    if (props.task === "CancleRecord") {
      this.setState({ modalData: props });
      this.setState({ modalShow: true });
    }

    if (props.task === "BanStudent") {
      this.setState({ modalData: props });
      this.setState({ modalShow: true });
    }

    if (props.task === "CreateRecord") {
      if (this.state.login === true) {
        this.register(props.taskData);
      } else {
        this.setState({
          modalData: {
            task: "goOkLink",
            modalText: {
              title: "Помилка",
              descript:
                `Ви не авторизовані!
                
                Для авторизації:
                -> натисніть на кнопку "Увійти"; 
                -> введіть логін/пароль; 
                -> натисніть "Записатись на відпрацювання". (для студентів 6 курсу реєстрація необов'язкова)`,
              ok: "Увійти",
              cancle: "Скасувати"
            },
            taskData: {
              okLink:
                "https://ifnmu.sharepoint.com/siteDeanMedical/SitePages/%D0%94%D0%BE%D0%BC%D0%B0%D1%88%D0%BD%D1%8F%D1%8F.aspx#"
            }
          }
        });
        this.setState({ modalShow: true });
      }
    }

    if (props.task === "CancleRecordAdmin") {
      this.CancleRecordAdmin(props.taskData);
    }
  };

  CancleRecordAdmin = props => {
    axios("https://listsever.e-decanat-ifnmu.site/api.php", {
      method: "post",
      data: {
        task: "CancleRecordAdmin",
        number_in_list: props.number_in_list,
        list_id: props.list_id,
        listIndex: props.listIndex
      },
      withCredentials: true
    }).then(response => this.CancleRecordAdminResult(response.data));
  };

  CancleRecordAdminResult = props => {
    props.data = "deleted";
    this.replaceRecord(props);
  };

  confirmedModalConformation = (task, taskData) => {
    this.setState({ modalShow: false });
    if (task === "CancleRecord") {
      this.cancleRegistration(taskData);
    }

    if (task === "goOkLink") {
      window.location.href = taskData.okLink;
    }
  };

  componentDidMount() {
    axios("https://listsever.e-decanat-ifnmu.site/api.php", {
      method: "post",
      data: {
        task: "GetTablesFromGroup",
        group_id: this.props.match.params.id
      },
      withCredentials: true
    }).then(response =>
      this.setState({ data: response.data.data, login: response.data.login })
    );
  }

  register = props => {
    console.log(props);
    axios("https://listsever.e-decanat-ifnmu.site/api.php", {
      method: "post",
      data: {
        task: "CreateRecord",
        number_in_list: props.number_in_list,
        list_id: props.list_id,
        listIndex: props.listIndex
      },
      withCredentials: true
    }).then(response => this.replaceRecord(response.data));
  };

  cancleRegistration = props => {
    axios("https://listsever.e-decanat-ifnmu.site/api.php", {
      method: "post",
      data: {
        task: "CancleRecord",
        number_in_list: props.number_in_list,
        list_id: props.list_id,
        listIndex: props.listIndex
      },
      withCredentials: true
    }).then(response => this.replaceRecord(response.data));
  };

  replaceRecord = response => {
    console.log(response);
    let data = this.state.data;
    data[response.listIndex].records[response.number_in_list - 1] =
      response.data;
    this.setState({ data: data });
    console.log(data);
    console.log(response.number_in_list);
  };

  handleClose = () => {
    console.log(this.state);
    this.setState({ modalShow: false });
  };

  showFreeRecords = listIndex => {
    let data = this.state.data;
    data[listIndex].showFreeRecords = !this.state.data[listIndex]
      .showFreeRecords;
    this.setState({ data: data });
  };

  render() {
    console.log(this.state);
    return (
      <div className="App">
        <Container>
          {this.state.data.map((listdata, index) => {
            listdata.ListIndex = index;
            return (
              <List
                key={index}
                listdata={listdata}
                showFreeRecords={this.showFreeRecords}
                buttonPress={this.buttonPress}
              />
            );
          })}

          <ModalConfirm
            show={this.state.modalShow}
            handleClose={this.handleClose}
            modalData={this.state.modalData}
            confirmedModalConformation={this.confirmedModalConformation}
          />
        </Container>
      </div>
    );
  }
}

export default PrintGroupList;
